<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos del operador" ref="pageForm" :items="items">
    </PageHeader>
    <visits-graphic ref="graphic" :options="options" :series="series"></visits-graphic>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4" v-if="filterReport">
              <template v-if="['APUESTAS POR DIA', 'VISITAS'].includes(filterReport.name)
      ">
                <div class="col-md-3 col-sm-9">
                  <label class="d-inline-flex align-items-center w-100">
                    <span class="m-2">{{ $t("filter.from") }}: </span>
                    <vs-input v-model="filterData.fromDate" type="date"></vs-input>
                  </label>
                </div>
                <div class="col-md-3 col-sm-9">
                  <label class="d-inline-flex align-items-center w-100">
                    <span class="m-2">{{ $t("filter.until") }}: </span>
                    <vs-input v-model="filterData.toDate" type="date"></vs-input>
                  </label>
                </div>
              </template>
            </div>
            <div class="col-sm-12 col-md-12 d-flex justify-content-start">
              <label class="d-inline-flex align-items-center" style="width: max-content">
                Reporte de:
              </label>
              <select v-model="filterReport.name" name="client"
                class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50" placeholder="Seleccione..">
                <option disabled selected>{{ $t("helpers.select") }}...</option>
                <option v-for="(item, index) in betsOrViews" :key="index" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 d-flex justify-content-start" @change="getGames">
              <label class="d-inline-flex align-items-center" style="width: max-content">
                {{ $t("filter.gameType") }}:
              </label>
              <select v-model="filterData.gameType" name="client"
                class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50" placeholder="Seleccione..">
                <option disabled selected>{{ $t("helpers.select") }}...</option>
                <option v-for="(item, index) in gamesTypes" :key="index" :value="item.value">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <template v-if="filterData.gameType && filterReport.name === 'APUESTAS POR DIA'
      ">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start">
                <label class="d-inline-flex align-items-center" style="width: max-content">
                  Juego:
                </label>
                <select v-model="filterData.roulette" name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50" placeholder="Seleccione..">
                  <option disabled selected>{{ $t("helpers.select") }}...</option>
                  <option v-for="(item, index) in roulettes" :key="index" :value="item._id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="isAdmin">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.client") }}:
                  <select v-model="filterData.clientId" @change="getOperatorClient(true)" name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option v-for="(item, index) in clients" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="userRole !== 'OPERATOR' && filterData.clientId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.operator") }}:
                  <select v-model="filterData.operatorId" name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option v-for="(item, index) in operators" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </template>
            <div class="d-flex align-items-center mt-2">
              <template v-if="filterReport">
                <vs-input placeholder="ROUND ID" border class="shadow-lg me-2" v-model="filterData.roundId"
                  v-if="filterReport.name === 'APUESTAS POR RONDA'" />
              </template>
              <div class="">
                <label class="d-inline-flex align-items-center w-100 m-1">
                  <vs-tooltip>
                    <span>{{ $t("filter.player") }} Admin:</span>
                    <vs-switch v-model="playerAdmin" class="m-1" />
                    <template #tooltip>Habilitar para filtrar con jugadores de test</template>
                  </vs-tooltip>
                </label>
              </div>
              <vs-button type="button" @click="filtrar()" :disabled="handleDisable()">
                {{ $t("filter.filter") }}
              </vs-button>
              <vs-button type="button" success @click="cancelFilter()" :disabled="!Object.entries(filterReport).length">
                {{ $t("filter.cancel") }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table id="transaction-table" :items="tableData" :fields="fields" responsive="sm" :sort-by="sortBy"
                  :sort-desc="sortDesc">
                  <template #cell(visits)="data">
                    <vs-tooltip left>
                      <template #tooltip>Click para visualizar las visitas a través de las
                        horas</template>
                      <vs-button @click="handleGraphicVisits(data.item)">
                        {{
      typeof data.item.visits === "number"
        ? data.item.visits
        : "Sin visitas"
    }}</vs-button>
                    </vs-tooltip>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <p class="text-center my-2" v-if="searchActive && !tableData.length && !loading">
              {{ $t("tableReports.noResults") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import { onlyAdmins } from "../../../../router/guards/roles";
import { mapActions, mapGetters } from "vuex";
import { formatRequest } from "../../../../state/helpers";
import VisitsGraphic from "./VisitsGraphic.vue";


export default {
  name: "BetsAndViews",
  components: {
    Layout,
    PageHeader,
    // Multiselect
    VisitsGraphic,
  },
  computed: {
    ...mapGetters({
      mapGames: "games/getGames",
      mapClients: "clients/getClients",
      mapOperators: "operators/getOperators",
      mapCurrencies: "currencies/getCurrencies",
      mapClientRoulettes: "clients/getClientRoulettes",
      mapOperatorRoulettes: "operators/getRoulettes",
      getViewsBets: "reports/getViewsBets",
      getFields: "reports/getFields",
      isAdmin: 'user/isAdmin',
      userRole: 'user/getRole',
      gamesTypes: 'reports/getGamesTypes',
    }),
  },
  data() {
    return {
      title: "APUESTAS Y VISITAS",
      items: breadCrumbData.betsAndViews,
      playerAdmin: false,

      // SEARCH
      searchActive: false,
      fetching: false,
      //Paginacion
      skip: 0,
      limit: 10,
      page: 0,
      //
      loading: false,
      filterData: {},
      userData: "",
      fileName: "Transacciones.xls",
      json_fields: {},
      tableData: [],
      // ROLES
      readAllRoles: onlyAdmins,
      // DATA FOR FILTER
      clients: [],
      operators: [],
      roulettes: [],
      currencies: [],
      sortBy: "createdAt",
      sortDesc: true,
      currentPage: 1,
      totalPages: "...",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterReport: {},
      //
      betsOrViews: [
        {
          name: "APUESTAS POR DIA",
        },
        {
          name: "APUESTAS POR RONDA",
        },
      ],
      //
      roulette: "",
      options: {},
      series: [],
    };
  },
  methods: {
    getUserRole() {

      if (this.userRole === "CLIENT") {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operatorId = this.userData.operator;
        this.getRoulettesOperator();
      } else {
        this.getClients();
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.mapClients;
      } catch (error) {
        console.log(error);
      }
    },
    async getOperatorClient(filter) {
      const clientId = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client: clientId } };
        await this.fetchOperators({ options });
        this.operators = this.mapOperators;
      } catch (err) {
        console.log("Error al obtener operadores del cliente", err);
      }
    },
    async handleGraphicVisits() {
      const { series, options } = await this.fetchViews();
      this.series = series;
      this.options = options;
      this.$refs.graphic.modal = true;
    },
    handleDisable() {
      if (!this.filterReport.name) return true;
      let isDisable = false;
      switch (this.filterReport.name) {
        case "APUESTAS POR DIA": {
          const { roulette } = this.filterData;
          if (!roulette) {
            isDisable = true;
          } else {
            isDisable = false;
          }
          break;
        }
        case "APUESTAS POR RONDA":
          isDisable = false;
          break;

        default:
          isDisable = true;
          break;
      }
      return isDisable;
    },
    addTag(newTag) {
      const tag = newTag;
      this.value.push(tag);
    },
    async getGames() {
      try {
        await this.fetchGames({ gameType: this.filterData.gameType });
        this.roulettes = this.mapGames;
      } catch (error) {
        console.log(error);
      }
    },
    async filtrar() {
      this.searchActive = true;
      this.loading = true;

      const formatted = formatRequest(this.filterReport, this.filterData);

      try {
        await this.fetchViewsBets({
          queries: formatted,
          filter: this.filterData,
        });

        this.fields = this.getFields;
        this.tableData = this.getViewsBets;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    cancelFilter() {
      this.filterReport = {};
      this.filterData = {};
      this.currentPage = 1;
      this.searchActive = false;
    },
    // PAGINATION
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    ...mapActions({
      fetchViewsBets: "reports/fetchViewsBets",
      fetchGames: "games/fetchGames",
      fetchViews: "reports/fetchVisits",
      fetchClients: "clients/fetchClients",
      fetchOperators: "operators/fetchOperators",
    }),
  },
  mounted() {
    this.getUserRole();
  },
  watch: {
    playerAdmin(data) {
      this.filterData.isAdmin = data ? data : false;
    },
  },
};
</script>
