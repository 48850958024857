<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      VISITAS
    </template>
    <span>Eje Y: Visitas</span>
    <apexchart width="100%" height="350" type="bar" :options="options" :series="series"></apexchart>
    <span>Eje X: Horas</span>
  </b-modal>
</template>

<script>
export default {
  props: {
    options: {
      type: Object
    },
    series: {
      type: Array
    }
  },
  data() {
    return {
      modal: false
    }
  }
}
</script>